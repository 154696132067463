import React from 'react';
import {withStyles} from '@material-ui/core';
import styles from 'components/commonStyles';
import PropTypes from 'prop-types';
import {graphql, StaticQuery, Link} from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash/get';
import classnames from 'classnames';
import {navigate} from '@reach/router';

const Case9 = ({classes}) => (
  <StaticQuery
    query={graphql`
      query {
        image: allFile(
          filter: { relativePath: { eq: "other/api-chooser/9.png" } }
        ) {
          edges {
            node {
              id
              childImageSharp {
                id
                fluid(
                  srcSetBreakpoints: [200, 340, 520, 800, 890]
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const img = get(data, 'image.edges[0].node.childImageSharp.fluid', {});

      const linkColor = classnames(classes.textColor, classes.link);

      const clickButton = () => {
        navigate('#sales-analysis');
      };

      return (
        <div className={classes.case} id="pos-server-consumes-data">
          <div className={classes.head}>
            <h4 className={classes.h4Absolute}>
              Use Case #9. POS server consumes data
            </h4>
          </div>
          <p className={classes.textHead} style={{marginBottom: 60}}>
            You have a server with data from your POS to be integrated with
            srv4pos.com where your server gets articles/sales from srv4pos.com.
          </p>
          <Img fluid={img} style={{maxWidth: 705}} />
          <p style={{marginTop: 60, marginBottom: 20}}>
            To fetch latest changes of taxes, categories, products and
            categories data use GET /modifications (getModifications()) . Keep
            providing seller version in from-to parameters to get historical
            information part-by-part. The idea is described in a very simple
            manner at
{" "}
            <Link to="/developers/sync/">
              <span className={classes.textColor}>
                Synchronization and Cashing
              </span>
            </Link>{" "}
            article.
          </p>
          <p>
            To fetch sales information from the server see{" "}
            <span className={linkColor} onClick={clickButton}>
              Use Case #7. Sales analysis
            </span>
          </p>
        </div>
      );
    }}
  />
);

Case9.propTypes = {
  classes: PropTypes.shape({
    case: PropTypes.string.isRequired,
    head: PropTypes.string.isRequired,
    h4Absolute: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, {withTheme: true})(Case9);
